<template>
  <div class="mainPage">
    <div class="loginBox">
      <p id="headTitle"> SIKU RAJA</p>
      <p id="title">SISTEM KEUANGAN KURNIA JAYA</p>
      <form @submit.prevent="login()"> 
        <p id="inputTitle">username</p>
        <input type="text"  required v-model="username" >
        <p id="inputTitle">password</p>
        <input type="password" v-model="password" required>
        <button type="submit"> LOGIN</button>
        <p id="copyright">Thou shalt not wrest judgment; thou shalt not respect persons, neither take a gift: for a gift doth blind the eyes of the wise, and pervert the words of the righteous.</p>
      </form>
      <!-- <input type="number" v-model="numberFormatted" @input="updateNumber" /> -->
    </div>

  </div>

</template>

<script>
import axios  from '../API/axios';
import Swal from "sweetalert2";
import moment from 'moment';
export default {

    name: "LoginPage",
    data() {
      return {
        username: '',
        password: "",
        number: 0,
        numberFormatted: '0'
      }
    },
    computed: {
      isLogin() {
        return this.$store.state.isLogin
      }
    },
    methods: {

      async login() {

        try {

          let data = await axios({
            method: 'post',
            url: "/user/login",
            data : {
              username: this.username,
              password: this.password
            }

          })
          let date = moment(new Date()).format("YYYY-MM-DD")
          console.log(data,"data");
          localStorage.setItem('access_token', data.data.access_token)
          localStorage.setItem('role', data.data.role)
          localStorage.setItem('username', data.data.username)
          localStorage.setItem('date', date)
          Swal.fire("Semangat Pagi", "Semangat Pagi, Semangat Bekerja", "success");
          this.$store.commit("setIsLogin", true);
          this.$store.dispatch('getLocalStorage')
          this.$router.push("/")

        } catch(err) {
          Swal.fire("Maaf", "Password/username anda salah", "error");
        }
      },
      
    }
}
</script>

<style scoped> 
.mainPage {
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ededed
}
.loginBox {
  background-color: #FFFFFF;
  border-radius:20px;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 32px;
  padding-bottom: 32px;
  /* border: 1px solid rgb(163, 162, 162); */
  width: 400px;
  
}
#headTitle {
  /* text-align: left; */
  color: #000080;
  font-size: 28px;
  margin: 0px;
  font-weight: 600;
}
#title {
  /* text-align: left; */
  font-size: 16px;
  color: #000080;
  margin: 0px;
  margin-bottom: 10px;
  margin-bottom: 70px;
  font-weight: 500;
}
#inputTitle{
  text-align: left;
  margin: 0;
}

input {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: left;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px #E0E0E0 solid;
}


button {
  margin-top: 10px;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ededed;
  border-radius: 20px;
  border: none;
  background-color: #00803C;
  
}

button:hover{
  color: #00803C;
  background-color: #FFFFFF;
}

form {
  display: flex;
  flex-direction: column;
}

#copyright {
  font-size: 11px;
}
</style>