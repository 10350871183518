<template>
  <div class="mainPage">
    <p id="headPage">SOLAR</p>
    
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="findBranchId" @change="this.getDataTable">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input v-model="keyword" @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
      </div>
      <div class="inRow">


      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()" v-if="role == 'solar'">Tambah Data</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <b-button id="addModal" class="ml-3" @click.prevent="showAddVehicle()" v-if="role == 'solar'">Tambah Kendaraan</b-button>
      <!-- <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button> -->
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Cabang</th>
            <th>No.Reff</th>
            <th>Kendaraan</th>
            <th>Driver</th>
            <th>Jenis Transaksi</th>
            <th>Jenis Pengisian</th>
            <th>Keterangan</th>
            <th>Jumlah</th>
            <!-- <th>Harga</th> -->
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{formatDate(e.date)}} </td>
            <td> {{e.Branch ? e.Branch.branchName : e.BranchId}} </td>
            <td> {{e.refNo}} </td>
            <td> {{e.Vehicle ? e.Vehicle.platNumber : '-'}} </td>
            <td> {{e.driver}} </td>
            <td> {{ e.mutasionType == 1 ? "Pengisian Stok" : "Pemakaian"  }} </td>
            <td> {{ cekType(e.type)  }} </td>
            <td> {{ e.notes || "-"}} </td>
            <td class="uang"> {{ formatUang(e.total) || ""}} </td>
            <!-- <td class="uang"> {{ formatUang(e.amount) || ""}} </td> -->
            <td id="action" class="text-center" >
              <button @click.prevent="setPrintData(e)" title="Print" >
                  <img src="../assets/print2.png" alt="">
                </button>
                <!-- <button @click.prevent="showEdit(e)" title="Edit" >
                  <lord-icon
                    src="https://cdn.lordicon.com/nxaaasqe.json"
                    trigger="hover">
                  </lord-icon>
                </button> -->
                <button v-if="role == 'owner'" @click.prevent="confirmDelete(e.id)" title="Hapus">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="hover">
                    </lord-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Solar</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Cabang</p>
              <b-select v-model="BranchId" required @change="this.getKendaraan">
                <option value="0" disabled>Pilih Cabang </option>
                <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Jenis Transaksi</p>
              <b-select v-model="mutasionType" required>
                <option value="1" > Pengisian Stok </option>
                <option value="2" > Pemakaian </option>
              </b-select>
            </div>
            <div class="inCol"  v-if="this.mutasionType == 2">
              <p id="titleModal">Jenis Pemakaian</p>
              <b-select v-model="type" required>
                <option value="1" > Pembelian Langsung </option>
                <option value="2" > Penggunaan Dari Stok </option>
              </b-select>
            </div>
            <div class="inCol" v-if="this.mutasionType == 2">
              <p id="titleModal">Kendaraan</p>
              <b-select v-model="VehicleId"  >
                <option v-for="e in this.vehicle" :key="e.id" :value="e.id"> {{e.platNumber}} </option>
              </b-select>
            </div>
        
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="inputTitle">Tanggal</p>
            <b-input type="date" v-model="date" required></b-input>
          </div>
          <div class="inCol"  v-if="this.mutasionType == 2">
            <p id="inputTitle">Driver</p>
            <b-input type="text" v-model="driver" required></b-input>
          </div>
          <div class="inCol">
            <p id="inputTitle">Jumlah (liter)</p>
            <b-input type="number" v-model="total" required></b-input>
          </div>
          <!-- <div class="inCol">
            <p id="inputTitle">Harga (Rp)</p>
            <b-input type="number" class="text-right" v-model="amount"></b-input>
          </div> -->
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="inputTitle">Keterangan</p>
            <b-input type="string" v-model="notes" required></b-input>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>
<!-- modal kendaraan -->
  <div class="modal" id="vehicleModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Kendaraan</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent=" addVehicle() ">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Cabang</p>
              <b-select v-model="BranchId" required @change="this.getKendaraan">
                <option value="0" disabled>Pilih Cabang </option>
                <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Kendaraan</p>
              <b-input v-model="platNumber" type="text" required></b-input>
            </div>
        
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>

  <!-- Print0 -->
  <div id="printArea">

<div class="headPrint">
  <p id="titlePrint">{{ print.mutasionType }}</p>
  <p id="brancName"> {{ print.branchName }} </p>
  <p id="datePrint"> {{ print.date }} </p>
</div>
<hr>
<div class="mainPrint">
  <table class="table table-borderless" id="printTable">
      <tr>
        <td id="keyTable">Nomor</td>
        <td id="titikDua">:</td>
        <td>{{ print.refNo }}</td>
      </tr>
      <tr>
        <td id="keyTable">Volume</td>
        <td id="titikDua">:</td>
        <td>{{ print.total }}</td>
      </tr>
      <tr>
        <td id="keyTable">Jumlah</td>
        <td id="titikDua">:</td>
        <td>Rp.{{ print.amount }}</td>
      </tr>
      <tr v-if="print.mutasionType == 'Pemakaian Solar'">
        <td id="keyTable" >Jenis Pembelian</td>
        <td id="titikDua">:</td>
        <td>{{ print.type }}</td>
      </tr>
      <tr >
        <td id="keyTable" >Keterangan</td>
        <td id="titikDua">:</td>
        <td>{{ print.notes }}</td>
      </tr>
  </table>
  <hr>
</div>
<div class="signArea">
  <div class="inRow">
    <div class="inCol">
      <p id="name">Staff</p>
      <p>(............................)</p>
    </div>
    <div class="inCol" v-if="print.mutasionType == 'Pemakaian Solar'">
      <p id="name" >Driver</p>
      <p>({{ print.driver }})</p>
    </div>
    <div class="inCol">
      <p id="name">Akuntansi</p>
      <p>(............................)</p>
    </div>
    <div class="inCol">
      <p id="name">Direktur</p>
      <p>(............................)</p>
    </div>
  </div>
</div>
<hr>
  </div>

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
import html2pdf from "html2pdf.js";
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      vehicle: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      startDate: '',
      endDate : '',
      // filter
      findVehicleId: 0,
      findVehicle: [],
      keyword: '',
      findBranchId: [],

    
      // modal
      VehicleId: 0,
      BranchId: 0,
      date:"",
      total: 0,
      driver: "",
      amount: 0,
      platNumber: "",
      mutasionType: 0,
      type: 0,
      notes: '',


      print: {
        branchName: '',
        driver: '',
        refNo: '',
        platNumber: '',
        date: '',
        total: '',
        type: '',
        mutasionType: '',
        notes: ''

      }
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem('role')
    }
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      this.VehicleId = 0,
      this.mutasionType = "",
      this.BranchId = 0,
      this.total = 0,
      this.driver = ""
      this.isEdit = false
      $('#mainModal').modal('show')
    },
    showAddVehicle() {

      $('#vehicleModal').modal('show')

    },
    showEdit(e) {
      this.getProject()
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
     this.BranchId=  e.BranchId
     this.date=  e.date
     this.notes=  e.notes
     this.amount=  e.amount
     this.ProjectId= e.ProjectId
     this.DebtVendorId= e.DebtVendorId
     this.transactionType= e.transactionType
     this.DebtTypeId= e.DebtTypeId
    },
    resetFilter() {
      this.findBranchId= 0
      this.findProjectId= 0
      this.findDebtVendorId= 0
      this.findDebtTypeId= 0
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/solar",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            keyword: this.keyword,
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,

          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addData() {

      try {
        
        let proses = await axios({
          method: "POST",
          url: "/solar",
          data: {
            BranchId : this.BranchId,
            mutasionType : this.mutasionType,
            date : this.date,
            driver : this.driver,
            total : this.total,
            VehicleId : this.VehicleId,
            // amount : this.amount,
            notes : this.notes,
            type : this.type,

          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.platNumber = "",
        this.BranchId = "",
        this.date = "",
        this.driver = "",
        this.total = "",
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        this.getDataTable()
        this.vehicle = data
      } catch(err) {
        console.log(err);
        this.isLoading = false
        this.getDataTable()
      }

    },
    async addVehicle() {

      try {
        
        let {data} = await axios({
          method: "POST",
          url: "/solar/vehicle",
          data: {
            platNumber : this.platNumber,
            BranchId : this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.platNumber = ""
        $('#vehicleModal').modal('hide')
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        this.vehicle = data
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getKendaraan() {

      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/solar/vehicle",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.BranchId,

          }
        })
        this.vehicle = data
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            BranchId: this.findBranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'daftar  per pegawai',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    cekStatus(data) {
      let result = ""
      if(data == 2) {
        result = 'By Planing'
      } else if(data == 1) {
        result= 'Urgent'
      }
      return result
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
      if(data) {
        data = data?.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
      }
			}
      return uang
    },


    async editData(e) {

      try {
        let payload = await {
          BranchId:  this.BranchId,
          date:  this.date,
          notes:  this.notes,
          amount:  this.amount,
          ProjectId: this.ProjectId,
          DebtVendorId: this.DebtVendorId,
          transactionType: this.transactionType,
          DebtTypeId: this.DebtTypeId,
        }

        let data2 = await axios({
          method: "put",
          url: `/debt/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.getDataTable()
        this.BranchId=  0
        this.date=  ""
        this.ProjectId=  ""
        this.DebtVendorId=  ""
        this.notes=  ""
        this.transactionType=  ""
        this.DebtTypeId=  ""
        this.amount=  0
        this.id=  0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/solar/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
          Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
        }        
      } catch(err) {
        Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    setPrintData(e) {
      let type = ''
      if(e.type == 1) type = 'Pembelian langsung'
      else if(e.type == 2) type = 'Pengambilan Stok'

      // console.log(e);
      this.print.branchName = e.Branch.branchName
      this.print.platNumber = e.Vehicle ? e.Vehicle.platNumber : '-'
      this.print.driver = e.driver
      this.print.refNo = e.refNo
      this.print.total = e.total
      this.print.notes = e.notes ? e.notes : '-'
      this.print.amount = this.formatUang(e.amount)
      this.print.type = type
      this.print.mutasionType = e.mutasionType == 1 ? 'Pengisian Solar' : 'Pemakaian Solar'
      this.print.date = this.formatDate(e.date)
      this.exportToPDF()
      
    },
    exportToPDF() {
      this.isLoading = true
      document.getElementById('printArea').style.display='block'
      html2pdf(document.getElementById('printArea'),  {
        margin: 1,
        filename: "Solar.pdf",
      }).then(e => {
        document.getElementById('printArea').style.display='none'
        this.isLoading = false
      })
    },
    cekType(data) {

      let result = '-'
      if(data == 1) result = 'Beli Langsung'
      else if(data == 2) result = 'Pemakaian Stok'
      return result
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>