import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../API/axios"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    branches: [],
    selectedJurnal: {},
    types : ['Mini Bus','Bus', 'Mixer',"Tronton", "Truk Ringan", "Truk Tangki", "Truk Crane", "Excavator", "Bulldozer", "Skid Steer Loader", "Pickup Truck", "Sepeda Motor", 'Lain-lain'],
    merk: ['TOYOTA','DAIHATSU','HYUNDAI','HINO','CATTERPILAR', 'MITSUBISHI','WULING','Komatsu', 'SCANIA', 'KOBELCO', 'Lain-lain'],
  },
  getters: {
  },
  mutations: {
    setIsLogin(state, data) {
      state.isLogin = data
    },
    setBranch(state, data) {
      state.branches = data
    },
    setJurnal(state, data) {
      state.selectedJurnal = data
    },
  },
  actions: {
    getLocalStorage(context) {
      if(localStorage.getItem("access_token")) {
        context.commit('setIsLogin', true)
      }
    },
    async getBranch(context) {

      try {

        let data = await axios({
          method: 'get',
          url: '/user/branch',
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        context.commit('setBranch', data.data)

      } catch(err) {
        console.log(err);
      }
    },
    getJurnal(context, data) {
      context.commit('setJurnal', data)
    }
  },
  modules: {
  }
})
