<template>
  <div class="mainPage">
    <p id="headPage">Tarif retase</p>
    

    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input v-model="keyword" @change="this.getDataTable"></b-input>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()" v-if="role !== 'owner'">Tambah Data</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <!-- <button @click.prevent="printCsv()" id="printer">
        <img src="../assets/printer.png" id="printer" alt="">
      </button> -->
    </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Asal - Tujuan</th>
            <th>Jenis Kendaraan</th>
            <th>Ukuran Kendaraan</th>
            <th>Tarif / Rate</th>
            <th >Aksi</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="e in dataTable" :key="e.id">
              <td>{{ e.departingFrom }} - {{ e.destination }}</td>
              <td>{{ e.truckType }}</td>
              <td>{{ e.truckSize }}</td>
              <td class="text-right">{{ formatUang(e.amount) }}</td>
              <td id="action" class="text-center">
                <button @click.prevent="showEdit(e)" title="Edit" >
                  <img src="../assets/edit.png" alt="">
                </button>
              
                <button @click.prevent="confirmDelete(e.id)" title="Hapus">
                  <img src="../assets/trash.png" alt="">
                </button>
              </td>
            </tr>
          
        </tbody>
      </table>

    </div>
    <div class="modal" id="mainModal" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Solar</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="isEdit ? editData() : addData()">

            <div class="inRow">
              <div class="inCol">
                <p id="inputTitle">Asal</p>
                <b-input type="text" v-model="add.departingFrom" required></b-input>
              </div>
              <div class="inCol">
                <p id="inputTitle">Tujuan</p>
                <b-input type="text" v-model="add.destination" required></b-input>
              </div>
              <div class="inCol">
                <p id="inputTitle">Jenis Kendaraan</p>
                <b-select v-model="add.truckType" required>
                  <option v-for="(e,index) in this.types" :key="index">{{ e }}</option>
                </b-select>
              </div>

              <div class="inCol">
                <p id="inputTitle">Ukuran Kendaraan</p>
                <b-select v-model="add.truckSize" required>
                  <option v-for="(e,index) in this.sizes" :key="index">{{ e }}</option>
                </b-select>
              </div>
              <div class="inCol">
                <p id="inputTitle">Jumlah</p>
                <b-input class="text-right" type="text" v-model="add.amount" @input="currencyNumber($event)"  required></b-input>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Tambah</button>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      isEdit: false,
      isLoading: false,
      keyword : '',
      dataTable: [],
      types: ['dalam','luar'],
      sizes: ['Roda 4','Roda 6','Roda 10', 'Roda 12'],
      add : {
        departingFrom: '',
        destination: '',
        truckType: '',
        truckSize: '',
        amount: 0
      },
      filter: {
        truckType: '',
        truckSize: '',
        keyword: ''
      }

    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem('role')
    }
  },  
  methods: {
    
    showAdd() {
      this.isEdit = false
      $('#mainModal').modal('show')
    },
    resetFilter() {
      this.keyword = ''
      this.getDataTable()

    },
    showEdit(e) {
      this.isEdit = true
      this.id = e.id
      this.add.departingFrom = e.departingFrom
      this.add.destination = e.destination
      this.add.truckSize = e.truckSize
      this.add.truckType = e.truckType
      this.add.amount = this.setEditCurrent(e.amount)
      $('#mainModal').modal('show')
    },
    async getDataTable() {
      // get kendaraan
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/retase/fare",
          params: {
            keyword: this.keyword
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.dataTable = data
        this.isLoading = false
        console.log(data);
              
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },
    currencyNumber(event, target) {
        console.log(event, "===================");
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.add.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
        this.add.amount = this.formatUang(currentValue)
        // currentValue = this.amount
        // this.amount = this.amount.toLocaleString('id-ID')
        console.log(this.amount, "++++++++++++++");
    },
    setEditCurrent(currentValue) {
      let result = 0
      if( Number.isNaN(Number(currentValue))) {
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
      }
      result = this.formatUang(currentValue)
      return result  
    },

    async addData() {
      this.add.amount = this.add.amount.trim().split('.').filter(e => e != '.' ).join('')
      try {
        let data = await axios({
          url: '/retase/fare',
          method: 'post',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getDataTable()
        $('#mainModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async editData() {
      this.add.amount = this.add.amount.trim().split('.').filter(e => e != '.' ).join('')
      try {
        let data = await axios({
          url: `/retase/fare/${this.id}`,
          method: 'put',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Diproses', 'success');
        this.getDataTable()
        $('#mainModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/retase/fare/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
        this.getDataTable()
      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },

  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>