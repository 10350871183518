<template v-if="this.isLogin"> 
    <div class="footer">
        <p id="ayat">Timbangan yang curang adalah kekejian bagi TUHAN, tetapi neraca yang benar adalah kesukaan-Nya</p>
        <p id="copyright">SikuRaja V.2.11 © otnielpangkung - 2023</p>
    </div>  
</template>

<script>
import axios from "../API/axios"
import Swal from "sweetalert2";
export default {
name: "Footer",
data() {
    return {
        password:""
    }
},
created() {
  this.$store.dispatch('getLocalStorage')
},
methods: {
    
},
computed: {
  isLogin() {
    return this.$store.state.isLogin
  }
},
}
</script>

<style scoped>
.footer {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 5px 10px;
    top: 0;
    /* background-color: rgb(244, 244, 244); */
    /* background-color: #ededed; */

    
}

#titleNavbar {
    font-size: 20px;
    font-weight: bold;
    color: #00803C;
    margin: 0
}
p {
    margin-bottom: 0px!important;
    font-size: 11px;
    font-weight: 500;
}
#ayat {
}
#userImg {
    width: 24px;
    height: 24px;
}
button {
    border: none;
    background-color: transparent;
}
.rightSide{
    display: flex;
}

</style>