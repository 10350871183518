<template>
  <div class="mainPage">
    <p id="headPage">Material</p>
    
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="findBranchId" @change="this.getDataTable">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Jenis Material</p>
          <b-select v-model="findMaterialId" @change="this.getDataTable">
            <option v-for="e in this.types" :key="e.id" :value="e.id"> {{ e.materialName }} </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Jenis Mutasi</p>
          <b-select v-model="findTransactionType" @change="this.getDataTable">
            <option :value="0"> Seluruh Mutasi </option>
            <option :value="1"> Masuk </option>
            <option :value="2"> Keluar </option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input type="text" v-model="keyword"  @change="this.getDataTable"></b-input>
        </div>
      </div>
      <div class="inRow">


      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah Data</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <b-button id="addModal" class="ml-3" @click.prevent="showAddType()">Tambah Jenis Material</b-button>
      <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>No.Reff</th>
            <th>Cabang</th>
            <th>Lokasi</th>
            <th>Jenis Material</th>
            <th>Jenis Mutasi</th>
            <th>keterangan</th>
            <th>Volume</th>
            <th>amount</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{formatDate(e.date)}} </td>
            <td>{{ e.refNo }}</td>
            <td>{{ e.Branch.branchName }}</td>
            <td>{{e.Project.projectName}} </td>
            <td>{{e.Material.materialName}} </td>
            <td>{{ e.transactionType == 1 ? 'Masuk' : 'Keluar' }}</td>
            <td>{{ e.notes }}</td>
            <td class="text-right">{{ formatUang(e.volume) }}</td>
            <td class="text-right">{{ formatUang(e.amount) }}</td>
            <td id="action" class="text-center" >
              <button @click.prevent="setPrintData(e)" title="Print" >
                  <img src="../assets/print2.png" alt="">
                </button>
                <!-- <button @click.prevent="showEdit(e)" title="Edit" >
                  <img src="../assets/edit.png" alt="">
                </button> -->
                <button @click.prevent="confirmDelete(e.id)" title="Hapus" >
                  <img src="../assets/trash.png" alt="">
                </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Mutasi Material</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Cabang</p>
              <b-select v-model="BranchId" required @change="this.getProject">
                <option value="0" disabled>Pilih Cabang </option>
                <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Project</p>
              <b-select v-model="ProjectId" required>
                <option v-for="e in this.projects" :key="e.id" :value="e.id"> {{e.projectName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="inputTitle">Tanggal</p>
              <b-input type="date" v-model="date" required></b-input>
            </div>
            
          </div>
          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Jenis Material</p>
              <b-select v-model="MaterialId" required>
                <option v-for="e in this.types" :key="e.id" :value="e.id"> {{e.materialName}} </option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Jenis Transaksi</p>
              <b-select v-model="transactionType" required>
                <option value="1"> Masuk </option>
                <option value="2"> Keluar </option>
              </b-select>
            </div>
            <div class="inCol" v-if="this.transactionType == 1">
              <p id="titleModal">Jenis Pembelian</p>
              <b-select v-model="purchaseType">
                <option value="Tunai"> Tunai </option>
                <option value="Kredit"> Kredit </option>
              </b-select>
            </div>
          </div>
          <div class="inRow">
            <div class="inCol">
              <p id="titleModal">Volume</p>
              <b-input v-model="volume" class="text-right" type="number"></b-input>
            </div>
            <div class="inCol" v-if="this.transactionType == 1">
              <p id="titleModal">harga</p>
              <b-input v-model="amount" class="text-right"  @input="currencyNumber($event)" type="text"></b-input>
            </div>
            <div class="inCol" >
              <p id="titleModal">Keterangan</p>
              <b-input v-model="notes" type="text"></b-input>
            </div>
          </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>
  <!-- Type Material -->
    <div class="modal" id="typeMaterial" tabindex="-1">
    <!-- <div class="modal-dialog"> -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Mutasi Material</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" >&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="  addType()">
              <div class="inCol">
                <p id="titleModal">Nama Material</p>
                <b-input type="text" v-model="materialName" required></b-input>
              </div>
             
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Tambah</button>
          </div>
        </form>
        </div>
      </div>
    <!-- </div> -->
  </div>
  <!-- Print -->
<div id="printArea">

  <div class="headPrint">
    <p id="titlePrint" v-if="this.print.transactionType == 1">Material Masuk</p>
    <p id="titlePrint" v-if="this.print.transactionType == 2">Material Keluar</p>
    <p id="brancName"> {{ print.branchName }} </p>
    <p id="datePrint"> {{ print.date }} </p>
  </div>
  <hr>
  <div class="mainPrint">
    <table class="table table-borderless" id="printTable">
        <tr>
          <td id="keyTable">Nomor</td>
          <td id="titikDua">:</td>
          <td>{{ print.nomor }}</td>
        </tr>
        <tr>
          <td id="keyTable">Jenis Material</td>
          <td id="titikDua">:</td>
          <td>{{ print.materialName }}</td>
        </tr>
        <tr>
          <td id="keyTable">Volume</td>
          <td id="titikDua">:</td>
          <td>{{ print.volume }}</td>
        </tr>
        <tr>
          <td id="keyTable">Total</td>
          <td id="titikDua">:</td>
          <td>Rp.{{ print.amount }}</td>
        </tr>
        <tr>
          <td id="keyTable">Keterangan</td>
          <td id="titikDua">:</td>
          <td>{{ print.notes }}</td>
        </tr>
    </table>
    <hr>
  </div>
  <div class="signArea">
    <div class="inRow">
      <div class="inCol">
        <p id="name">Staff Gudang</p>
        <p>(............................)</p>
      </div>
      <div class="inCol" v-if="this.print.transactionType == 1">
        <p id="name">Pemberi</p>
        <p>(............................)</p>
      </div>
      <div class="inCol" v-if="this.print.transactionType == 2">
        <p id="name">Penerima</p>
        <p>(............................)</p>
      </div>
      <div class="inCol">
        <p id="name">Akuntansi</p>
        <p>(............................)</p>
      </div>
      <div class="inCol">
        <p id="name">Direktur</p>
        <p>(............................)</p>
      </div>
    </div>
  </div>
  <hr>
</div>

<!-- Vehicle -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'
import html2pdf from "html2pdf.js";
export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      dataTable: [],
      projects: [],
      types: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      isEdit: false,
      endDate: '',
      startDate: '',
      
      // filter
      findMaterialId: 0,
      findBranchId: 0,
      findTransactionType: 0,
    
      // modal
      BranchId: 0,
      ProjectId: 0,
      transactionType: 0,
      purchaseType: "Tunai",
      date:"",
      volume: 0,
      amount: 0,
      notes: "",
      MaterialId: 0,
      keyword: "",
      materialName: "",

      print: {
        branchName: '',
        projectName: '',
        date: '',
        nomor: '',
        materialName: '',
        notes: '',
        amount: '',
        volume: '',
        type: 0,
        transactionType: 0
      }
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getType()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {

    showAdd() {
      this.isEdit = false
      this.ProjectId = 0,
      this.mutasionType = "",
      this.BranchId = 0,
      this.total = 0,
      this.amount = ""
      this.isEdit = false
      $('#mainModal').modal('show')
    },
    showAddType() {
      this.materialName = ''
      $('#typeMaterial').modal('show')
    },
    async addType() {

      try {
        let proses = await axios({
          method: "POST",
          url: "/material/type",
          data: {
            materialName : this.materialName,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#typeMaterial').modal('hide')
        this.materialName = "",
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err);
      }

    },
    showEdit(e) {
      this.getProject()
      this.id = e.id
      this.isEdit = true
      $('#mainModal').modal('show')
     this.BranchId=  e.BranchId
     this.date=  e.date
     this.notes=  e.notes
     this.amount=  e.amount
     this.ProjectId= e.ProjectId
     this.DebtVendorId= e.DebtVendorId
     this.transactionType= e.transactionType
     this.DebtTypeId= e.DebtTypeId
     this.MaterialId= e.MaterialId
    },
    resetFilter() {
      this.findBranchId= 0
      this.findProjectId= 0
      this.findDebtVendorId= 0
      this.findDebtTypeId= 0
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.findTransactionType = 0
      this.getDataTable()
    },
    
    setPrintData(e) {
      console.log(e);
      this.print.branchName = e.Branch.branchName,
      this.print.date = moment(e.date).format('DD-MM-YYYY')
      this.print.nomor = e.refNo
      this.print.amount = this.formatUang(e.amount)
      this.print.notes = e.notes,
      this.print.transactionType = e.transactionType,
      this.print.volume = this.formatUang(e.volume),
      this.print.materialName = e.Material.materialName,
      this.print.projectName = e.Project.projectName
      this.exportToPDF()
    },
    exportToPDF() {
      this.isLoading = true
      document.getElementById('printArea').style.display='block'
      html2pdf(document.getElementById('printArea'),  {
        margin: 1,
        filename: "Material.pdf",
      }).then(e => {
        document.getElementById('printArea').style.display='none'
        this.isLoading = false
      }).catch(err => {
        console.log(err);
        this.isLoading = false
      })
    },
    async getProject() {
      try {
        let project = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = project.data
        this.getVendor()
      } catch(err) {
        console.log(err);
      }
    },
    
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/material",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            transactionType: this.findTransactionType,
            endDate: this.endDate,
            keyword: this.keyword,
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,
            MaterialId: this.findMaterialId,

          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },

    async addData() {

      try {
        
        let proses = await axios({
          method: "POST",
          url: "/material",
          data: {
            BranchId : this.BranchId,
            purchaseType : this.purchaseType,
            transactionType : this.transactionType,
            VendorId : this.VendorId,
            MaterialId : this.MaterialId,
            ProjectId : this.ProjectId,
            amount :  this.amount.trim().split('.').filter(e => e != '.' ).join(''),
            volume : this.volume,
            date : this.date,
            notes : this.notes,

          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        // this.platNumber = "",
        // this.BranchId = "",
        // this.date = "",
        // this.amount = "",
        // this.total = "",
        $('#mainModal').modal('hide')
        this.getDataTable()
        Swal.fire('Berhasil', 'Data berhasil diproses', 'success');
        
      } catch(err) {
        Swal.fire('Berhasil', 'Data berhasil diproses', 'error');
        console.log(err);
        this.isLoading = false
        console.log(err);
      }

    },

    // Material Type

    async getType() {
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/material/type",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.types = data
      } catch(err) {
        console.log(err);
      }
    },

    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/material/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            transactionType: this.findTransactionType,
            keyword: this.keyword,
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,
            MaterialId: this.findMaterialId,
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'daftar absen per pegawai',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    cekStatus(data) {
      let result = ""
      if(data == 2) {
        result = 'By Planing'
      } else if(data == 1) {
        result= 'Urgent'
      }
      return result
    },

    currencyNumber(event, target) {
        console.log(event, "===================");
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
        this.amount = this.formatUang(currentValue)
        // currentValue = this.amount
        // this.amount = this.amount.toLocaleString('id-ID')
        console.log(this.amount, "++++++++++++++");
    },

    formatUang(data) {
      // console.log(data);
			let uang = "";
      if(data) {
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },

    async editData(e) {

      try {
        let payload = await {
          BranchId:  this.BranchId,
          date:  this.date,
          notes:  this.notes,
          amount:  this.amount,
          ProjectId: this.ProjectId,
          DebtVendorId: this.DebtVendorId,
          transactionType: this.transactionType,
          DebtTypeId: this.DebtTypeId,
        }

        let data2 = await axios({
          method: "put",
          url: `/debt/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        $('#mainModal').modal('hide')
        this.getDataTable()
        this.BranchId=  0
        this.date=  ""
        this.ProjectId=  ""
        this.DebtVendorId=  ""
        this.notes=  ""
        this.transactionType=  ""
        this.DebtTypeId=  ""
        this.amount=  0
        this.id=  0
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data =await  axios.delete(`/material/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.getDataTable()
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
          } else {
          this.getDataTable()
          Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
        }
      } catch(err) {
        this.getDataTable()
        console.log(err);
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>