<template>
  <div class="mainPage">
    <p id="headPage">Surat Jalan</p>
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Mulai</p>
          <b-input type="date" v-model="find.startDate" @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="find.endDate" @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Kendaraan</p>
          <b-select v-model="find.TransportId"  @change="this.getDataTable">
            <option :value="e.id" v-for="e in this.transports" :key="e.id">{{ e.merk }} - {{ e.type }} - {{ e.platNumber }}</option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input type="text" class="text-right" v-model="find.keyword" @change="this.getDataTable"></b-input>
        </div>

      </div>
    </div>
    <div id="titleRow">
      <b-button id="addModal" @click.prevent="showAdd()">Tambah</b-button>
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>No Ref</th>
            <th>Tanggal</th>
            <th>Jam</th>
            <th>Plat Nomor</th>
            <th>Keluar / Masuk</th>
            <th>Keterangan</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{ e.refNo }}</td>
            <td class="text-left">{{ formatDate(e.date) }}</td>
            <td>{{ e.time }}</td>
            <td>{{ e.Transport?.platNumber || e.TransportId }}</td>
            <td>{{ e.type == 1 ? "Masuk" : "Keluar" }}</td>
            <td>{{ e.notes }}</td>
            <td id="action" class="text-center" >
                <!-- <button @click.prevent="setPrintData(e)" title="Print" >
                  <img src="../assets/print2.png" alt="">
                </button> -->
                <button @click.prevent="confirmDelete(e)" title="Print" >
                  <img src="../assets/trash.png" alt="">
                </button>
              </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
    <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Piutang</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

          <div class="inRow">
            <div class="inCol" v-if="role == 'owner'">
              <p id="titleModal">Nama Cabang</p>
              <b-select v-model="add.BranchId">
                <option :value="e.id" v-for="e in this.branches" :key="e.id">{{ e.branchName }}</option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Kendaraan</p>
              <b-select v-model="add.TransportId" required>
                <option :value="e.id" v-for="e in this.transports" :key="e.id">{{ e.merk }} - {{ e.type }} - {{ e.platNumber }}</option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Jenis Mutasi</p>
              <b-select v-model="add.type" required>
                <option value="1">Masuk</option>
                <option value="2">Keluar</option>
              </b-select>
            </div>
            <div class="inCol">
              <p id="titleModal">Tanggal</p>
              <b-input v-model="add.date" type="date" required></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Waktu</p>
              <b-input v-model="add.time" type="time" required></b-input>
            </div>
            <div class="inCol">
              <p id="titleModal">Keterangan</p>
              <b-input v-model="add.notes" type="text" required></b-input>
            </div>
        
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      isEdit: false,
      dataTable: [],
      transports: [],
      currentPage: 1,
      perPage: 40,
			totalPage : 1,
      isLoading: false,
      totalBalance: "",
      
      // filter

      find: {
        type: "",
        merk: "",
        status: '',
        keyword: '',
        BranchId: '',
        TransportId: 0
      },
      add: {
        BranchId: '',
        date: '',
        type: '',
        time: '',
        TransportId: '',
        notes: '',
      },

      // add
      BranchId: 0,
      vendorName : ""
    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()
    this.getTransport()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem("role");
    },
    merk() {
      return this.$store.state.merk
    },
    types() {
      return this.$store.state.types
    },
    
  },  
  methods: {
    resetFilter() {
      this.find = {
        type: "",
        size: "",
        status : '',
        keyword: ''
      }
      this.getDataTable()
    },
    showAdd() {
      let date = moment(new Date()).format("YYYY-MM-DD")
      let time = moment(new Date()).format("HH:mm")
      this.isEdit = false
      $('#mainModal').modal('show')
      this.add = {
        BranchId: '',
        date: date,
        type: '',
        time: time,
        TransportId: '',
        notes: '',
      }
    },
    showEdit(e) {
      let date = moment(new Date()).format("YYYY-MM-DD")
      let time = moment(new Date()).format("hh:mm")
      this.isEdit = true
      $('#truckModal').modal('show')
      this.id = e.id
      this.add = {
        BranchId: '',
        date: "",
        type: '',
        time: '',
        TransportId: '',
        notes: '',
      }
    },

    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/transport/mutation",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.find.BranchId,
            type: this.find.type, 
            startDate: this.find.startDate, 
            endDate: this.find.endDate, 
            TransportId: this.find.TransportId, 
            keyword: this.find.keyword, 
          }
        })
        this.dataTable = data.data2.result
        console.log(this.dataTable, "dataaa");
        this.totalBalance = data.totalBalance
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getTransport() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/sparepart/transport",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
          }
        })
        this.transports = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async addData() {
      try {
        let data = await axios({
          url: '/transport/mutation',
          method: 'post',
          data: this.add,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Ditambahkan', 'success');
        this.getDataTable()
        $('#mainModal').modal('hide')
      } catch (err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/transport/mutation/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.getDataTable()

      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) {
            this.deleteData(id);
            this.getDataTable()
        } else {
          Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'success');
        }
              
              
      } catch(err) {
        
        Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },

    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/balance/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.findBranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Saldo Piutang Krayawan / Dana gantung',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatDate(date) {
      let result = ''
      if(date) result = moment(date).format("DD-MM-YYYY")
      return result
    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }





</style>