<template>
  <div class="mainPage">
    <p id="headPage">History APD</p>
    <!-- {{ sparepart}} -->

    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">

        <div class="inCol" id="cardFilter">
          <p id="inputTitle" >Tanggal Mulai</p>
          <b-input type="date"  v-model="startDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Tanggal Selesai</p>
          <b-input type="date" v-model="endDate"  @change="this.getDataTable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Kata Kunci</p>
          <b-input v-model="keyword" @change="this.getDataTable"></b-input>
        </div>


      </div>
      <div class="inRow">


      </div>
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Nomor Ref.</th>
            <th>Keterangan</th>
            <th>Nama Sparepart</th>
            <th>Jenis Transaksi</th>
            <th>Jumlah</th>
            <th>Total Harga</th>
            <!-- <th colspan="2">Aksi</th> -->

          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{ formatDate(e.date) }}</td>
            <td> {{ e.ApdBill.refNo }}</td>
            <td> {{ e.ApdBill.notes }}</td>
            <td><b> {{ e.Apd.name }}</b></td>
            <td> {{ e.type == 1 ? 'Masuk' : 'Keluar'}} </td>
            <td class="text-right"> {{ formatUang(e.volume) }}</td>
            <td class="text-right">{{ formatUang(e.amount) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->
  <div class="modal" id="mainModal" tabindex="-1">
  <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Mutasi Product</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" >&times; </span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="isEdit ? editData() : addData()">

        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Cabang</p>
            <b-select v-model="add.BranchId" required @change="this.getProject">
              <option value="0" disabled>Pilih Cabang </option>
              <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="titleModal">Project</p>
            <b-select v-model="add.ProjectId" required>
              <option v-for="e in this.projects" :key="e.id" :value="e.id"> {{e.projectName}} </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="titleModal">Tanggal</p>
            <b-input type="date" v-model="add.date" required></b-input>
          </div>
        </div>
        <div class="inRow">
          <div class="inCol">
            <p id="titleModal">Jenis Mutasi</p>
            <b-select v-model="add.type" required>
              <option :value="1"> Masuk </option>
              <option :value="2"> Keluar </option>
            </b-select>
          </div>
          <div class="inCol">
            <p id="titleModal">Keterangan</p>
            <b-input type="text"  v-model="add.notes" placeholder="Masukkan Catatan" required ></b-input>
          </div>
        </div>
        <div class="inRow" style="justify-content: flex-start;">
          <b-button class="mt-3 " id="addModal" @click.prevent="addRow()">Tambah Baris</b-button>
        </div>

        <div class="inRow"  style="justify-content: space-around;" >
          <p>Jenis Sparepart</p>
          <p>Volume</p>
          <p v-if="this.add.type == 1">Total Harga</p>
        </div>
        <div class="inRow" v-for="e in add.subBill" :key="e.id">
          <b-select v-model="e.SparepartId">
            <option v-for="e in sparepart" :key="e.id" :value="e.id">{{ e.name}} - {{ e.type }}</option>
          </b-select>
          <b-input class="text-right" v-model="e.volume" min="1"></b-input>
          <b-input class="text-right" v-model="e.amount" min="100" v-if=" add.type == 1"></b-input>
          <button @click.prevent="deleteRow(e.id)">
            <img src="../assets/trash.png" alt="">
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="subm/apdit" class="btn btn-primary"> {{ isEdit ? 'Edit' : 'Tambah' }} </button>
        </div>
      </form>
      </div>
    </div>
  <!-- </div> -->
</div>

<!-- Vehicle -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'

export default {
  name: "Voucer",
  data() {
    return  {
      BranchId: 0,
      ProduckId: 0,
      dataTable: [],
      id: 0,
      currentPage: 1,
      perPage: 20,
			totalPage : 2,
      isLoading: false,
      isEdit: false,
      startDate: '',
      endDate: '',
      type: 1,
      keyword :'',
      SparepartId: 0,
      // fill modal
      sparepart: [],
      projects: [],
      // filter
      subBill: [],
      add: {
        BranchId: 0,
        ProjectId: 0,
        date: '',
        type: 1,
        notes: '',
        subBill: []
      }
    }
  },
  created() {
    this.getDataTable()

    // this.getSparepart()
    this.$store.dispatch("getBranch")
  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {

    addRow() {
      // alert("yeess")
      this.add.subBill.push({
        id: uuidv4(),
          SparepartId: 0,
          volume: 0,
          amount: 0
      })
    },

    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/apd/subbill",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.BranchId,
            SparepartId: this.SparepartId,
            keyword: this.keyword,
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
          }
        })
        this.dataTable = data.data2.result
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    async getProject() {

      try {
        let {data} = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.add.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = data
      } catch(err) {
        console.log(err);
      }
    },

    async getSparepart() {
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/apd",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        console.log(data);
        this.sparepart = data

      } catch(err) {

        console.log(err);
      }
    },

    async addData() {


      try {
        let payload = this.add
        let proses = await axios({
          method: "POST",
          url: "/apd/mutation",
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.materialName = "",
        this.getDataTable()
        $('#mainModal').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err.response.data);
        this.isLoading = false
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async editData(e) {

      try {

        if(this.subItems.length < 1) {
          throw {
            status: 500,
            msg: 'No'
          }
        }

        this.subItems.map(e => {
          if(e.MaterialId == 0 || e.volume == 0) {
            throw {
            status: 500,
            msg: 'Data is not complete'
          }
          }
        })

        let payload = {
            items: this.subItems,
            ProduckId: this.id,
            produckName : this.produckName,
            type : this.type,
        }
        let data3 = await axios({
          method: "post",
          url: `/apd/standar`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        let data2 = await axios({
          method: "put",
          url: `/apd/${this.id}`,
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })



        $('#mainModal').modal('hide')
        this.produckName=  ""
        this.type=  ""
        this.id=  0
        this.isEdit = false
        this.getDataTable()
        this.$store.dispatch("getBranch")

        Swal.fire('Berhasil', 'Data Berhasil Diubah', 'success');
        
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    }, 

    async deleteData(id) {
      
      try {
        
        let data = await axios.delete(`/apd/mutation/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Berhasil', 'Data Berhasil Dihapuskan', 'success');
        this.$store.dispatch("getBranch")
        this.getDataTable()
      } catch(err) {
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');

      } 
    },
    async confirmDelete(id) {
      
      try {
        let data = await Swal.fire({
            title: 'Yakin?',
            text: 'Apakah Yakin ',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus!!',
        })
        if (data.isConfirmed) { 
            await this.deleteData(id);
            this.$store.dispatch("getBranch")
            this.getDataTable()
        } else {
                Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
              }
              
              
            } catch(err) {
              
              Swal.fire('Maaf', 'Proses anda berhasil dibatalkan', 'error');
      }
    },
    totalAmount(data) {

      let total = 0
      data.map(e => {
        total += e.amount
      })

      return total
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    deleteRow(id) {
      this.add.subBill = this.add.subBill.filter(e => e.id !== id)
    },  
    
    addPage() {
      this.currentPage = this.currentPage + 1
          this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
          this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }



 




</style>