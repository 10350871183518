<template>
  <div>
    <select id="mySelect" v-model="selectedOption" data-select2 @input="filterOptions">
      <option v-for="option in options" :value="option.value" :key="option.value">{{ option.text }}</option>
    </select>
  </div>
</template>

<script>
import 'select2/dist/css/select2.css';

export default {
  data() {
    return {
      selectedOption: null,
      options: [
        { value: 1, text: 'Pilihan 1' },
        { value: 2, text: 'Pilihan 2' },
        // ...
      ],
    };
  },
  mounted() {
    $('#mySelect').select2({
      minimumInputLength: 0,
    });
  },
  methods: {
    filterOptions() {
      const searchText = $('#mySelect').val();
      const filteredOptions = this.options.filter(option =>
        option.text.toLowerCase().includes(searchText.toLowerCase())
      );
      $('#mySelect').empty().select2({ data: filteredOptions });
    },
  },
};
</script>
