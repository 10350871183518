<template>
  <div class="mainPage">
    <p id="headPage">SALDO PENAGIHAN</p>
    <div class="cardRow">
      <div id="titleRow"> </div>
      <div class="inRow">
        <div class="card" id="balanceCard">
          <p id="titleCard">Saldo Akhir</p>
          <p id="amountCard">{{formatUang(totalBalance)}}</p>
        </div>
      </div>
    </div>
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="findBranchId" @change="this.getFindVendor">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Proyek</p>
          <b-select v-model="findProjectId" @change="this.getDataTable">
            <option  v-for="e in this.findProcejts" :key="e.id" :value="e.id"> {{e.projectName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Vendor</p>
          <b-select v-model="findDebtVendorId"  @change="this.getDataTable">
            <option v-for="e in this.findVendors" :key="e.id" :value="e.id"> {{e.vendorName}} </option>
          </b-select>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <!-- <button @click.prevent="printCsv()" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button> -->
    </div>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p> 
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Cabang</th>
            <th>Project</th>
            <th>Vendor</th>
            <th>Jumlah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.dataTable" :key="e.id">
            <td>{{e.Branch.branchName}} </td>
            <td>{{e.Project.projectName}} </td>
            <td> {{e.ReceivableVendor.vendorName}}</td>
            <td class="uang"> {{ formatUang(e.amount)}} </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
      </div>
    </div>

    <!-- modal -->

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      projects: [],
      vendors: [],
      dataTable: [],
      types: [],
      currentPage: 1,
      perPage: 20,
			totalPage : 1,
      isLoading: false,
      totalBalance: "",
      
      // filter
      findBranchId: 0,
      findProjectId: 0,
      findDebtVendorId: 0,
      findDebtTypeId: 0,
      endDate: "",
      startDate: "",
      keyword: "",
      findVendors: [],
      findProcejts: [],
    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()

  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {
    resetFilter() {
      this.findBranchId= 0
      this.findProjectId= 0
      this.findDebtVendorId= 0
      this.findDebtTypeId= 0
      this.endDate= ""
      this.startDate= ""
      this.keyword= ""
      this.getDataTable()
    },
    async getFindVendor() {
      try {
        this.getFIndProject()
        this.getDataTable()
        let vendor = await axios({
          url: "/receivable/vendor",
          params: {
           BranchId:  this.findBranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.findVendors = vendor.data
      } catch(err) {
        console.log(err);
      }
    },  
    async getFIndProject() {
      try {
        let project = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.findBranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.findProcejts = project.data
      } catch(err) {
        console.log(err);
      }
    },  

    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            BranchId: this.findBranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Penagihan',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/receivable/balance",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.currentPage,
            limit: this.perPage,
            BranchId: this.findBranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        this.totalBalance = data.totalBalance
        this.dataTable = data.data2.result
        this.totalBalance = data.totalBalance
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/balance/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.findBranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.findProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Saldo Piutang Krayawan / Dana gantung',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    addPage() {
      this.currentPage = this.currentPage + 1
      this.getDataTable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1
      this.getDataTable()
    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>