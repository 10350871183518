<template>
  <div class="mainPage">
    <p id="headPage">Laba Rugi</p>
    <div class="cardRow">
      <div id="titleRow"> </div>
      <div class="inRow">
        <div class="card" id="inCard">
          <p id="titleCard">Total Pendapatans</p>
          <p id="amountCard">{{formatUang( netIncome )}}</p>
        </div>
        <div class="card" id="outCard">
          <p id="titleCard">Total Biaya</p>
          <p id="amountCard">{{formatUang( totalCost )}}</p>
        </div>
        <div class="card" id="balanceCard" v-if="this.netProfit >= 0">
          <p id="titleCard">Laba </p>
          <p id="amountCard">{{formatUang( netProfit )}}</p>
        </div>
        <div class="card" id="lossCard" v-if="this.netProfit < 0">
          <p id="titleCard">Laba </p>
          <p id="amountCard">{{formatUang( netProfit )}}</p>
        </div>
      </div>
    </div>
    <!-- {{ totalCost }} -->
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Cabang</p>
          <b-select v-model="BranchId" @change="this.getProject">
            <option value="0" disabled>Pilih Cabang </option>
            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Proyek</p>
          <b-select v-model="ProjectId" @change="this.getAmount">
            <option value="0">Semua Project</option>
            <option  v-for="e in this.projects" :key="e.id" :value="e.id"> {{e.projectName}} </option>
          </b-select>
        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Bulan</p>
          <b-select v-model="month"  @change="this.getAmount">
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </b-select>

        </div>
        <div class="inCol"  id="cardFilter">
          <p id="inputTitle">Tahun</p>
          <b-select v-model="year"  @change="this.getAmount">
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
          </b-select>
        </div>
      </div>
    </div>
    <div id="titleRow">
      <b-button id="resetFilter" @click.prevent="resetFilter()">Reset Filter</b-button>
      <b-button class="button ml-2" variant="warning" @click.prevent="closedReport()" v-if="this.role == 'owner'">Tutup Sementara</b-button>
      <b-button class="button ml-2" variant="danger" @click.prevent="closedReporPermanent()" v-if="this.role == 'owner'">Tutup Permanent</b-button>
      <button @click.prevent="tableHtmlToExcel('tableData')" id="printer">
          <img src="../assets/printer.png" id="printer" alt="">
      </button>
    </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Mata Anggaran</th>
            <th>Anggaran</th>
            <th>Tahun {{ this.year }}</th> 
            <th>Tahun Sebelumnya</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="e in this.mak">
            <tr >
              <td id="kelompok">{{e.kelompokName}} </td>
              <td class="uang"  id="kelompok">{{ formatUang(filterBudget(e.id,0,0)) }}</td>
              <td class="uang" id="kelompok"> {{ formatUang(filterAmount(e.id, 0,0)) }} </td>
              <td class="uang" id="kelompok"> {{ formatUang(filterLastAmount(e.id, 0,0)) }} </td>
            </tr>
            <template v-for="x in e.MataAnggarans"> 
              <tr>
                <td id="ma">{{ x.mataAnggaranName }}</td>
                <td  id="ma" class="uang">{{ formatUang(filterBudget(0,x.id,0)) }}</td>
                <td class="uang" id="ma"> {{ formatUang(filterAmount(0, x.id,0)) }} </td>
                <td class="uang" id="ma"> {{ formatUang(filterLastAmount(0, x.id,0)) }} </td>
              </tr>
              <template v-for="y in x.SubMataAnggarans"> 
              <tr>
                <td id="subma">{{ y.subMataAnggaranName }}</td>
                <td class="uang"><a @click.prevent="showModal(y)">{{ formatUang(filterBudget(0,0,y.id)) }}</a> </td>
                <td class="uang" id="subma"> {{ formatUang(filterAmount(0, 0,y.id)) }} </td>
                <td class="uang" id="subma"> {{ formatUang(filterLastAmount(0, 0,y.id)) }} </td>
              </tr>
            </template>
            </template>
          </template>
        </tbody>
      </table>

    </div>

    <!-- modal Budget-->
    <div class="modal" id="mainModal" tabindex="-1">
    <!-- <div class="modal-dialog"> -->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Budget</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" >&times; </span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addBudget()">
            <div class="inRow">
              <div class="inCol">
                <p id="titleModal">Total Budget</p>
                <b-input class="text-right" v-model="amount" name="detail" type="text" @input="currencyNumber($event)"  required></b-input>
              </div>
            </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Tambah</button>
          </div>
        </form>
        </div>
      </div>
    <!-- </div> -->
    </div>
  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      id: 0,
      BranchId: 0,
      ReportTypeId: 2,
      ProjectId: 0,
      SubMataAnggaranId: 0,
      amount: 0,
      mak:[],
      projects: [],
      dataTable: [],
      year: 2023,
      month: "",
      isLoading : false,
      
      kelmaNow: [],
      maNow: [],
      submaNow: [],
      
      netIncome: 0,
      totalCost: 0,
      brutoProfit : 0,
      netProfit : 0,
      kelmaLast: [],
      maLast: [],
      submaLast: [],
      
      budgets: [],

      // filter

    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    // this.getDataTable()
    this.getMak()
    this.getAmount()
    // this.getBudget()
  },
  computed: {
    branches() {
      return this.$store.state.branches
    },
    role() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    resetFilter() {
      this.BranchId= 0
      this.ProjectId= 0
      this.keyword= ""
      this.month=""
      this.getAmount()
    },
    async showModal(e) {
      try {
        $('#mainModal').modal('show')
        this.SubMataAnggaranId = e.id

        console.log(e);
      } catch(err) {
        console.log(err);
      }
    },
    async getBudget(e) {
      try {
        let data = await axios({
          method: 'get',
          url: '/budget',
          params: {
            year: this.year,
            ProjectId: this.ProjectId,
            BranchId: this.BranchId,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        console.log(data.data,"data------");
        this.budgets = data.data
      } catch(err) {
        console.log(err);
      }
    },

    async closedReport() {

      try {
        let data = await axios({
          url: '/report/close/month',
          method: 'POST',
          data: {
            month: this.month,
            year: this.year,
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Ok', 'Data Anda Berhasil Diproses', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali Data Anda', 'error');
      }

    },
    async closedReporPermanent() {

      try {
        let data = await axios({
          url: '/report/close',
          method: 'POST',
          data: {
            year: this.year,
            BranchId: this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        Swal.fire('Ok', 'Data Anda Berhasil Diproses', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali Data Anda', 'error');
      }

    },

    async addBudget() {
      try {

        if(!+this.ProjectId || !+this.BranchId) {
          throw {
            status: 500,
            msg: "Data Not Complete"
          }
        }

        let payload = {
          BranchId : this.BranchId,
          ProjectId : this.ProjectId,
          SubMataAnggaranId : this.SubMataAnggaranId,
          year : this.year,
          amount : this.amount.trim().split('.').filter(e => e != '.' ).join(''),
        }

        let data = await axios({
          method: 'post',
          url: '/budget',
          data: payload,
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })

        $('#mainModal').modal('hide')
        Swal.fire('Ok', 'Data Anda Berhasil Diproses', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    
    
    async getProject() {
      try {
        let project = await axios({
          url: "/user/project",
          params: {
           BranchId:  this.BranchId
          },
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.projects = project.data
        this.getAmount()
      } catch(err) {
        console.log(err);
      }
    },
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            BranchId: this.BranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.ProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'daftar absen per pegawai',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    
    tableHtmlToExcel(tableID,filename = `Laporan Keuangan Tahunan`   ) {
        // Download Transaksi
        var downloadLink;
        var dataType = "application/vnd.ms-excel";
        var tableSelect = document.getElementById(tableID);
        var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

        filename = filename ? filename + ".xls" : "excel_data.xls";

        downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
          var blob = new Blob(["\ufeff", tableHTML], {
            type: dataType
          });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          downloadLink.href = "data:" + dataType + ", " + tableHTML;

          downloadLink.download = filename;

          downloadLink.click();
        }
    },
    formatUang(data) {
      // console.log(data);
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
      return uang
    },
    async getMak() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/report/kelma",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            ReportTypeId: this.ReportTypeId,
          }
        })
        this.mak = data
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async getAmount() {

      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/report/jumlah",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            ReportTypeId: this.ReportTypeId,
            BranchId: this.BranchId,
            ProjectId: this.ProjectId,
            year: this.year,
            month: this.month,
          }
        })
        console.log(data);
        // let hpp = data.kelma.find(e => e.KelompokMaId == 2).amount || 0
        // let cost = data.kelma.find(e => e.KelompokMaId == 3).amount || 0
        this.totalCost = data.cost
        this.netIncome = data.income
        // this.netIncome =  data.kelma.find(e => e.KelompokMaId == 1).amount || 0
        
        this.kelmaNow = data.kelma
        this.maNow = data.ma
        this.submaNow = data.subma
        this.brutoProfit = data.brutoProfit
        this.netProfit = data.netProfit
        this.getBudget()
        this.getLastAmount()
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },
    async getLastAmount() {

      // this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/report/lastjumlah",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            ReportTypeId: this.ReportTypeId,
            BranchId: this.BranchId,
            ProjectId: this.ProjectId,
            year: this.year
          }
        })
        this.kelmaLast = data.kelma
        this.maLast = data.ma
        this.submaLast = data.subma
        this.isLoading = false

      } catch(err) {
        this.isLoading = false
        console.log(err);
      }
    },
    filterBudget(kel,ma,sub) {
      let result = 0
      if(+kel) {
        this.budgets.map(e => {
          if(e.KelompokMaId == kel) result = Number(result) + Number(e.amount)
        })

      } else if(+ma) {
        this.budgets.map(e => {
          if(e.MataAnggaranId == ma) result = Number(result) + Number(e.amount)
        })
      } else if(+sub) {
        result =  this.budgets.find(e => e.SubMataAnggaranId == sub)?.amount || 0
      }

      return result
    },
    filterAmount(kel,ma,sub) {
      if(kel !== 0) {
        return this.kelmaNow.find(e => e.KelompokMaId == kel)?.amount || 0
      } else if(ma !== 0) {
        return this.maNow.find(e => e.MataAnggaranId == ma)?.amount || 0
      } else if(sub !== 0) {
        return this.submaNow.find(e => e.SubMataAnggaranId == sub)?.amount || 0
      }
    },
    filterLastAmount(kel,ma,sub) {
      if(kel !== 0) {
        return this.kelmaLast.find(e => e.KelompokMaId == kel)?.amount || 0
      } else if(ma !== 0) {
        return this.maLast.find(e => e.MataAnggaranId == ma)?.amount || 0
      } else if(sub !== 0) {
        return this.submaLast.find(e => e.SubMataAnggaranId == sub)?.amount || 0
      }
    },
    currencyNumber(event, target) {
        console.log(event, "===================");
        let currentValue = event.trim().split('.').filter(e => e != '.' ).join('')

        console.log(currentValue, typeof currentValue," -----------");
        if( Number.isNaN(Number(currentValue))) {
          // this.amount = 10
          this.amount = this.formatUang(currentValue.split('').filter(e => !Number.isNaN( Number(e))).join(''))
          return
        }
        // currentValue = this.amount
        this.amount = this.formatUang(currentValue)
        // currentValue = this.amount
        // this.amount = this.amount.toLocaleString('id-ID')
        console.log(this.amount, "++++++++++++++");
    },
    async getDataTable() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/database/balance",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            ReportTypeId: this.ReportTypeId,
            BranchId: this.BranchId,
            ProjectId: this.ProjectId, 
          }
        })

        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    async printCsv() {
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/debt/balance/print",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            BranchId: this.BranchId,
            DebtVendorId: this.findDebtVendorId, 
            DebtTypeId: this.findDebtTypeId, 
            ProjectId: this.ProjectId, 
          }
        })
        const dataToConvert = {
          data: data,
          filename: 'Saldo Piutang Krayawan / Dana gantung',
          delimiter: ',',
        }
        csvDownload(dataToConvert)
        this.isLoading = false
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }

  #tableData td {
    font-size: 12px;
    padding: 3px 3px 3px auto;
  }
  #kelompok {
    font-size: 12px;
    background-color: antiquewhite;
    font-weight: 800;
  }
  #ma{
    font-weight: 700;
    padding-left: 10px;
  }
  #subma{
    padding-left: 20px;
  }
  #lossCard {
    background-color: rgb(255, 0, 0);
  }




</style>