<template>
  <div class="mainPage">
    <p id="headPage">Saldo Solar</p>
    
    <div class="filterRow">
      <div id="titleRow"></div>
      <div class="inRow">
      </div>
    </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="this.isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px">
        </lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Cabang</th>
            <th>Volume (Liter)</th>
            <th>Total (Rp.)</th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="e in dataTable" :key="e.id">
              <!-- <td>{{ e }}</td> -->
              <td>{{ e.Branch.branchName }}</td>
              <td class="text-right">{{ formatUang(e.volume) }}</td>
              <td class="text-right">{{ formatUang(e.amount) }}</td>
            </tr>
          
        </tbody>
      </table>

    </div>

  </div>
</template>

<script>
import axios  from '../API/axios'
import Swal from "sweetalert2";
import moment from 'moment'
import csvDownload from 'json-to-csv-export'

export default {
  name: "Voucer",
  data() {
    return  {
      isLoading: false,
      dataTable: [],

    
    }
  },
  created() {
    this.$store.dispatch("getBranch")
    this.getDataTable()

  },
  computed: {
    branches() {
      return this.$store.state.branches
    }
  },  
  methods: {


    async getDataTable() {
      // get kendaraan
      this.isLoading = true
      try {
        
        let {data} = await axios({
          method: "GET",
          url: "/solar/balance",
          headers: {
            access_token: localStorage.getItem("access_token")
          },
        })
        this.dataTable = data
        this.isLoading = false
        console.log(data);
              
      } catch(err) {
        this.isLoading = false
        console.log(err);
      }

    },
    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },

  }
}
</script>

<style scoped>
  .cardRow {
    /* display: flex; */
    width: 100%;
    /* background-color: aqua; */
  }




</style>